import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.gsdcsysuser.widget.group.user');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-gsdcsysuser-widget-group-user',
template: templateSource || '',
    styles: [`

/* file: /var/www/gsdc/project/main/build/src/app/portal.gsdcsysuser.widget.group.user/view.scss */
/* src/app/portal.gsdcsysuser.widget.group.user/view.scss: no such file or directory */`],
})
export class PortalGsdcsysuserWidgetGroupUserComponent implements OnInit {
    // @Input() view: any;
    @Input() group: any;
    @Input() community_id: any;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/login");
        await this.load();
    }

    public loading: boolean = false;
    public list: any = [];

    public search: any = {
        text: "",
        community_id: "",
        group_id: "",
        page: 1,
        dump: 30
    }

    public pagination: any = {
        end: -1,
        start: -1,
        current: 0
    }

    public async load(page: number = 1) {
        if (this.loading) return;
        this.loading = true;
        this.pagination.current = page;
        this.search.page = page;
        this.list = [];
        this.search.community_id = this.community_id;
        this.search.group_id = this.group.id;
        await this.service.render();

        const { code, data } = await wiz.call("search", this.search);

        if (code == 200) {
            let { rows, lastpage } = data;
            const startpage = Math.floor((page - 1) / 10) * 10 + 1;
            this.list = rows;
            this.filteredList = this.list;  // 필터링된 리스트
            this.pagination.start = startpage;
            this.pagination.end = lastpage;
        }

        this.loading = false;
        await this.service.render();
    }

    public async filterByRole(role: string) {
        this.search.role = role;
        await this.load();
    }

    public roleLabels: { [key: string]: string } = {
        'admin': '시스템관리자',
        'manager': '서비스담당자',
        'leader': '커뮤니티대표',
        'pi': '그룹대표',
        'user': '개인연구자'
    };
    public getRoleLabel(role: string): string {
        return this.roleLabels[role] || role; // 변환된 값이 없으면 원래 값을 반환
    }

    public async changePI(user: any) {
        user.representative = user.representative === 'Y' ? 'N' : 'Y'
        let res = false;

        if (user.representative == 'Y') {
            res = await this.service.alert.show({ title: "PI 지정", message: "해당 사용자를 PI로 지정하시겠습니까?", action: "지정", cancel: "취소", actionBtn: 'success', status: 'success' });
        } else {
            res = await this.service.alert.show({ title: "PI 해제", message: "해당 사용자를 PI 해제하시겠습니까?", action: "해제", cancel: "취소", actionBtn: 'error', status: 'error' });
        }
        if (!res) return user.representative = user.representative === 'Y' ? 'N' : 'Y';

        const { code, data } = await wiz.call("changePI", { "user": JSON.stringify(user), "group": JSON.stringify(this.group) });
        if (code != 200) return;
        await this.service.alert.success("PI 지정/해제 완료했습니다.");
    }
}

export default PortalGsdcsysuserWidgetGroupUserComponent;