import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.season.pagination');
import { OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
    selector: 'wiz-portal-season-pagination',
template: templateSource || '',
    styles: [`

/* file: /var/www/gsdc/project/main/build/src/app/portal.season.pagination/view.scss */
.pagenation {
  width: 100%;
  display: flex;
  justify-content: center;
}

.btn {
  border-radius: 32px;
  width: 24px;
  height: 24px;
  margin: 0 3px;
}
.btn.disabled {
  opacity: 0.2;
}`],
})
export class PortalSeasonPaginationComponent implements OnInit, OnChanges {
    @Input() current: any = 1;
    @Input() start: any = 1;
    @Input() end: any = 1;
    @Input() maxlength: any = 10;
    @Output() pageMove = new EventEmitter<number>();

    public list: Array<number> = [];

    public async ngOnInit() {
        this.Math = Math;
    }

    public async ngOnChanges() {
        this.list = [];
        for (let i = 0; i < this.maxlength; i++) {
            if (this.start + i > this.end) break;
            this.list.push(this.start + i);
        }
    }

    public move(page: number) {
        this.pageMove.emit(page);
    }
}

export default PortalSeasonPaginationComponent;