import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.community');
import { OnInit, DoCheck } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-community',
template: templateSource || '',
    styles: [`

/* file: /var/www/gsdc/project/main/build/src/app/page.community/view.scss */
/* src/app/page.community/view.scss: no such file or directory */`],
})
export class PageCommunityComponent implements OnInit, DoCheck {
    constructor(@Inject( Service) public service: Service) { }

    public category: string = '';
    public menu: string = '';
    public id: string = '';

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/login");

        this.category = WizRoute.segment.category;
        this.menu = WizRoute.segment.menu;
        this.id = WizRoute.segment.id;

        await this.service.render();

        if (!WizRoute.segment.category)
            return this.service.href("/community/notice/list");
        else if (!WizRoute.segment.menu)
            return this.service.href(`/community/${WizRoute.segment.category}/list`);

        if (this.id) return await this.load();
    }

    public async ngDoCheck() {
        if (!WizRoute.segment.category)
            return this.service.href("/community/notice/list");
        else if (!WizRoute.segment.menu)
            return this.service.href(`/community/${WizRoute.segment.category}/list`);

        let reload: boolean = (this.id != WizRoute.segment.id && WizRoute.segment.id);

        this.category = WizRoute.segment.category;
        this.menu = WizRoute.segment.menu;
        this.id = WizRoute.segment.id;

        if (reload) {
            // this.load();
        }
    }

    public isActive(link: string) {
        return location.pathname.indexOf(link) === 0
    }

    public activeClass(link: string) {
        if (this.isActive(link)) {
            return "group flex gap-x-3 rounded-md bg-gray-50 py-2 pl-2 pr-3 text-sm font-semibold leading-6 text-indigo-600";
        }
        return "group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600";
    }

}

export default PageCommunityComponent;