import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.gsdcsysuser.group.item');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-gsdcsysuser-group-item',
template: templateSource || '',
    styles: [`

/* file: /var/www/gsdc/project/main/build/src/app/portal.gsdcsysuser.group.item/view.scss */
/* src/app/portal.gsdcsysuser.group.item/view.scss: no such file or directory */`],
})
export class PortalGsdcsysuserGroupItemComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public group: any = null;
    public community: any = {
        id: "",
        name: ""
    }
    // public sub: any;
    // public group_id: any;
    @Input() sub: any;
    @Input() group_id: any;
    public community_id: any;

    public async ngOnInit() {
        await this.service.init();
        // this.group_id = WizRoute.segment.id;
        // this.sub = WizRoute.segment.sub;
        this.community_id = this.service.auth.session.community_id;
        await this.service.render();
        await this.load();
    }

    public async load() {
        const { code, data } = await wiz.call("info", { id: this.group_id });
        this.group = data.info;
        this.community = data.community;
        await this.service.render();
    }

    public linkGroupMenu(sub: string) {
        return `/group/${this.group_id}/${sub}`;
    }

    public isActive(link: string) {
        return location.pathname.indexOf(link) === 0
    }

    public activeClass(link: string) {
        if (this.isActive(link)) {
            return "group flex gap-x-3 rounded-md bg-gray-50 py-2 pl-2 pr-3 text-sm font-semibold leading-6 text-indigo-600";
        }
        return "group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600";
    }
}

export default PortalGsdcsysuserGroupItemComponent;