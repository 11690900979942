import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.lpsys.community.view');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-lpsys-community-view',
template: templateSource || '',
    styles: [`

/* file: /var/www/gsdc/project/main/build/src/app/portal.lpsys.community.view/view.scss */
/* src/app/portal.lpsys.community.view/view.scss: no such file or directory */`],
})
export class PortalLpsysCommunityViewComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() category: any;
    @Input() id: any;

    public loaded: boolean = false;

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/");
        await this.load();
    }

    public async load() {

    }

}

export default PortalLpsysCommunityViewComponent;