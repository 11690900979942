import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.gsdcsysuser.group.user');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-gsdcsysuser-group-user',
template: templateSource || '',
    styles: [`

/* file: /var/www/gsdc/project/main/build/src/app/portal.gsdcsysuser.group.user/view.scss */
/* src/app/portal.gsdcsysuser.group.user/view.scss: no such file or directory */`],
})
export class PortalGsdcsysuserGroupUserComponent implements OnInit {
    // @Input() view: any;
    // @Input() group: any;
    // @Input() community_id: any;
    public community_id: any;
    public list: any = [];

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/login");
        await this.load();
    }

    public loading: boolean = false;
    public list: any = [];
    public filteredList: any = [];
    public target_user: any = null;

    public search: any = {
        showAdmin: false,
        text: "",
        role: "all",
        community_id: "",
        group_id: "",
        page: 1,
        dump: 30
    }

    public pagination: any = {
        end: -1,
        start: -1,
        current: 0
    }

    public async load(page: number = 1) {
        if (this.loading) return;
        this.loading = true;
        this.pagination.current = page;
        this.search.page = page;
        this.list = [];
        await this.service.render();

        const { code, data } = await wiz.call("search", this.search);

        if (code == 200) {
            let { rows, lastpage } = data;
            const startpage = Math.floor((page - 1) / 10) * 10 + 1;
            this.list = rows;
            this.filteredList = this.list;  // 필터링된 리스트
            this.pagination.start = startpage;
            this.pagination.end = lastpage;
        }

        this.loading = false;
        await this.service.render();
    }
}

export default PortalGsdcsysuserGroupUserComponent;