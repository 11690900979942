import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.gsdcsysadmin.community.list');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-gsdcsysadmin-community-list',
template: templateSource || '',
    styles: [`

/* file: /var/www/gsdc/project/main/build/src/app/portal.gsdcsysadmin.community.list/view.scss */
/* src/app/portal.gsdcsysadmin.community.list/view.scss: no such file or directory */`],
})
export class PortalGsdcsysadminCommunityListComponent implements OnInit {
    @Input() view: any;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        if (this.view == "admin") await this.service.auth.allow.membership('admin', "/landing");
        else await this.service.auth.allow(true, "/landing");
        await this.load();
    }

    public loading: boolean = false;
    public list: any = [];
    public search: any = {
        text: "",
        page: 1,
        dump: 10000000
    }

    public pagination: any = {
        end: -1,
        start: -1,
        current: 0
    }

    public async load(page: number = 1) {
        if (this.loading) return;
        this.loading = true;
        this.pagination.current = page;
        this.search.page = page;
        this.list = [];
        await this.service.render();

        const { code, data } = await wiz.call("search", this.search);

        if (code == 200) {
            let { rows, lastpage } = data;
            const startpage = Math.floor((page - 1) / 10) * 10 + 1;
            this.list = rows;
            this.pagination.start = startpage;
            this.pagination.end = lastpage;
        }

        this.loading = false;
        await this.service.render();
    }
}

export default PortalGsdcsysadminCommunityListComponent;