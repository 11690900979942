import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.board.id');
import { OnInit, Input, DoCheck } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-board-id',
template: templateSource || '',
    styles: [`

/* file: /var/www/gsdc/project/main/build/src/app/page.board.id/view.scss */
/* src/app/page.board.id/view.scss: no such file or directory */`],
})
export class PageBoardIdComponent implements OnInit, DoCheck {

    public id: any = '';
    public category: any = '';

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/login");
        this.id = WizRoute.segment.id;
        this.category = WizRoute.segment.category;
        await this.service.render();
    }

    public async ngDoCheck() {
        this.id = WizRoute.segment.id;
        this.category = WizRoute.segment.category;
    }

    public isActive(link: string) {
        return location.pathname.indexOf(link) === 0
    }

    public activeClass(link: string) {
        if (this.isActive(link)) {
            return "group flex gap-x-3 rounded-md bg-gray-50 py-2 pl-2 pr-3 text-sm font-semibold leading-6 text-indigo-600";
        }
        return "group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600";
    }
}

export default PageBoardIdComponent;