import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.gsdcsysadmin.board.list');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-gsdcsysadmin-board-list',
template: templateSource || '',
    styles: [`

/* file: /var/www/gsdc/project/main/build/src/app/portal.gsdcsysadmin.board.list/view.scss */
/* src/app/portal.gsdcsysadmin.board.list/view.scss: no such file or directory */`],
})
export class PortalGsdcsysadminBoardListComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() category: any;
    public NAMESPACE: any;

    public categoryMap: any = { notice: '공지사항', manual: '매뉴얼', qna: '문의사항', expert: '전문가풀' };

    public items: any = [];
    public loaded: boolean = false;

    public search: any = { page: 1, text: '' };

    public pagination: any = {
        end: -1,
        start: -1,
    };

    public async ngOnInit() {
        this.NAMESPACE = 'board-' + this.category;
        if (this.service.status.get(this.NAMESPACE)) {
            this.search = this.service.status.get(this.NAMESPACE);
            this.service.status.unbind(this.NAMESPACE);
        }
        await this.service.init();
        await this.service.auth.allow.membership('admin', "/landing");
        this.search.category = this.category;
        await this.load(this.search.page);
    }

    public async load(page: number = 1) {
        this.search.page = page;
        this.loaded = false;
        this.items = [];
        await this.service.render();
        const { code, data } = await wiz.call("search", this.search);
        if (code != 200) return;
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.items = rows;
        this.pagination.start = startpage;
        this.pagination.end = lastpage;
        this.loaded = true;
        await this.service.render();
    }

    public async move(item) {
        this.service.status.bind(this.NAMESPACE, this.search);
        await this.service.href(`/admin/board/${this.category}/${item.id}`);
    }

}

export default PortalGsdcsysadminBoardListComponent;