import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.gsdcsysadmin.widget.user.profile');
import { OnInit, Input } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-gsdcsysadmin-widget-user-profile',
template: templateSource || '',
    styles: [`

/* file: /var/www/gsdc/project/main/build/src/app/portal.gsdcsysadmin.widget.user.profile/view.scss */
/* src/app/portal.gsdcsysadmin.widget.user.profile/view.scss: no such file or directory */`],
})
export class PortalGsdcsysadminWidgetUserProfileComponent implements OnInit {
    @Input() user_id: any;
    @Input() event: any = {};

    public data: any = null;
    public field: any = '';
    public sub_field: any = '';
    public passwd: any = {};
    public total: any = {};

    constructor(@Inject( Service) public service: Service) { }

    async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    public async load() {
        const { data } = await wiz.call("load", { id: this.user_id });
        this.data = data;
        if (!this.data.extra) this.data.extra = {};

        if (this.data.field) this.data.field = this.data.field.split(",");
        else this.data.field = [];

        if (this.data.sub_field) this.data.sub_field = this.data.sub_field.split(",");
        else this.data.sub_field = [];

        await this.service.render();
    }

    public async deleteBusinessCard() {
        this.data.extra.business_card = '';
        await this.service.render();
    }

    public async uploadBusinessCard() {
        let files = await this.service.file.select({ accept: 'image/*', multiple: false });
        if (files.length == 0) return;

        let fd = new FormData();
        fd.append('upload', files[0]);
        let fdurl = '/file/upload';
        const { url } = await this.service.file.upload(fdurl, fd, async (percent: number, total: number, position: number) => { });
        this.data.extra.business_card = url;
        this.service.render();
    }

    public timestamp() {
        return new Date().getTime();
    }

    public async update() {
        await this.service.loading.show();
        let body = JSON.parse(JSON.stringify(this.data));
        body.field = body.field.join(",")
        body.sub_field = body.sub_field.join(",")
        const { code, data } = await wiz.call("update", { id: this.user_id, data: JSON.stringify(body) });
        this.passwd = {};

        await this.load();
        await this.service.loading.hide();
        await this.service.alert.show({ title: "마이페이지", message: "회원 정보가 변경되었습니다.", action: "확인", actionBtn: "success", cancel: false, status: "success" });
    }

    public async updatePassword() {
        if (this.passwd.change != this.passwd.repeat) {
            await this.alert("비밀번호가 일치하지 않습니다");
            return
        }

        if (this.passwd.change.length < 8) return await this.alert("8글자 이상의 비밀번호를 설정해주세요");
        if (this.passwd.change.search(/[a-z]/i) < 0) return await this.alert("비밀번호에 알파벳을 포함해주세요");
        if (this.passwd.change.search(/[0-9]/) < 0) return await this.alert("비밀번호에 숫자를 포함해주세요");

        await this.service.loading.show();
        let passwd: any = {
            id: this.user_id,
            change: this.service.auth.hash(this.passwd.change)
        }
        const { code } = await wiz.call("update_password", passwd);
        await this.service.loading.hide();
        if (code == 200) {
            await this.service.alert.show({ title: "마이페이지", message: "비밀번호가 변경되었습니다.", action: "확인", actionBtn: "success", cancel: false, status: "success" });
            this.passwd = {};
        } else {
            await this.alert("비밀번호를 확인해주세요");
        }
        await this.service.render();
    }

    public async alert(message) {
        await this.service.alert.show({ title: "마이페이지", message: message, action: "확인", actionBtn: "error", cancel: false, status: "error" });
    }

    public async add(key, item) {
        if (!item || (this.data[key] && this.data[key].includes(item))) return;
        if (this.data[key]) this.data[key].push(item);
        else this.data[key] = [item];
        this[key] = "";
        await this.service.render();
    }

    public async remove(key, target) {
        this.data[key].remove(target);
        await this.service.render();
    }

    public tab: any = 'edit';

    public async switchTab(tab: any) {
        this.tab = null;
        await this.service.render();
        if (tab == 'info') await this.info();
        else await this.load();
        this.tab = tab;
        await this.service.render();
    }

    public activeTab(tab: any) {
        if (this.tab == tab) return 'cursor-pointer rounded-md bg-gray-200 px-3 py-2 text-sm font-medium text-gray-800 flex items-center';
        return 'cursor-pointer rounded-md px-3 py-2 text-sm font-medium text-gray-600 hover:text-gray-800 flex items-center';
    }

    public async closeUserModal() {
        if (this.event.close)
            await this.event.close();
    }

    public async info() {
        const { code, data } = await wiz.call("info", { id: this.user_id });

        let res = [];
        this.total = { buy: 0, sell: 0 }

        for (let i = 0; i < data.length; i++) {
            let rows = this.iterItems(data[i]);

            let total = 0;
            for (let j = 0; j < rows.length; j++) {
                let row = rows[j];

                row.meta = {};
                row.meta.sell1 = this.calc(row, 0);
                row.meta.sell2 = this.calc(row, 1);
                row.meta.sell3 = this.calc(row, 2);
                row.meta.total = 0;
                if (row.meta.sell1.amount) row.meta.total = row.meta.total + row.meta.sell1.amount * 1;
                if (row.meta.sell2.amount) row.meta.total = row.meta.total + row.meta.sell2.amount * 1;
                if (row.meta.sell3.amount) row.meta.total = row.meta.total + row.meta.sell3.amount * 1;
                total += row.meta.total;
            }

            let buytotal = 0;
            for (let j = 0; j < rows.length; j++) {
                let row = rows[j];
                row.total_sell = total;
                buytotal = row.member.applied_amount * row.amount_per_share;
                res.push(row);
            }

            this.total.buy += buytotal;
            this.total.sell += total;
        }

        this.data = res;
        await this.service.render();
    }

    public iterItems(item: any) {
        if (item.investment.length == 0) {
            let row: any = JSON.parse(JSON.stringify(item));
            row.investment = {};
            row._rowspan = 1;
            return [row];
        }

        let rows: any = [];
        for (let i = 0; i < item.investment.length; i++) {
            let row: any = JSON.parse(JSON.stringify(item));
            if (i == 0) row._rowspan = item.investment.length;
            row.investment = item.investment[i];
            rows.push(row);
        }

        return rows;
    }

    public translateStatus(item) {
        if (item.investment_status == '운용') return 'operating';
        if (item.investment_status == '해산') return 'exit';
        return 'formed';
    }

    public calc(item, target) {
        try {
            let investment_id = item.investment.id;
            let sells = item.member.extra.sell[investment_id];
            return sells[target];
        } catch (e) {
        }
        return {};
    }

}

export default PortalGsdcsysadminWidgetUserProfileComponent;