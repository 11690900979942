import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.gsdcsysadmin.research.item');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { ElementRef, ViewChild } from '@angular/core';
import ClassicEditor from "src/libs/portal/season/ckeditor/ckeditor";
import $ from "jquery";
import moment from "moment";

@Component({
    selector: 'wiz-portal-gsdcsysadmin-research-item',
template: templateSource || '',
    styles: [`

/* file: /var/www/gsdc/project/main/build/src/app/portal.gsdcsysadmin.research.item/view.scss */
/* src/app/portal.gsdcsysadmin.research.item/view.scss: no such file or directory */`],
})
export class PortalGsdcsysadminResearchItemComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() research_id: any;
    @Input() category: any;


    public research: any = {};

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow.membership('admin', "/");
        await this.load();
        await this.service.loading.hide();
    }

    public async load() {
        if (this.research_id != 'new') {
            const { code, data } = await wiz.call("get", { id: this.research_id });
            if (code != 200) return;

            this.research = data.research;
            if (this.research.extra != null) this.research.extra = JSON.parse(this.research.extra.replace(/'/g, '"'));
        } else {
            this.research = {
                title: '',
                extra: [],
                created: moment().format("YYYY-MM-DD")
            };
        }

        await this.service.render();

        this.research.created = moment(this.research.created).format("YYYY-MM-DD");
        this.research.updated = moment(this.research.updated).format("YYYY-MM-DD");

        await this.service.render();
    }

    public async update() {
        if (this.research.title == "") await this.service.alert.error("제목을 입력해주세요.")
        let data: any = JSON.stringify(this.research);
        await wiz.call("update", { data: data });
        await this.service.alert.success('저장되었습니다');

        if (!this.research.id) {
            await this.service.href(`/admin/research`);
        }
    }

    public async delete() {
        let res: any = await this.service.alert.error("정말로 삭제하시겠습니까?", "취소", "삭제");
        if (!res) return;
        await wiz.call("delete", { id: this.research.id });
        await this.service.href(`/admin/research`);
    }

    public async upload() {
        let files = await this.service.file.select({ accept: '.pdf,.hwp' });
        if (files.length == 0) return;

        await this.service.loading.show();

        if (!this.research.extra || this.research.extra == "") this.research.extra = [];
        console.log("extra : ", this.research.extra)

        for (let i = 0; i < files.length; i++) {
            let fd = new FormData();
            fd.append('upload', files[i]);
            let fdurl = '/file/upload';
            const { url } = await this.service.file.upload(fdurl, fd, async (percent: number, total: number, position: number) => { });
            this.research.extra.push({ name: files[i].name, url: url });
        }

        await this.service.loading.hide();
        this.service.render();
    }

    public async removeFile(file) {
        let res: any = await this.alert("정말로 삭제하시겠습니까?", "", "error", "삭제", "취소");
        if (!res) return;
        this.research.extra.remove(file);
        this.service.render();
    }
}

export default PortalGsdcsysadminResearchItemComponent;