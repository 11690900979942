import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.gsdcsysadmin.board.item');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { ElementRef, ViewChild } from '@angular/core';
import ClassicEditor from "src/libs/portal/season/ckeditor/ckeditor";
import $ from "jquery";
import moment from "moment";

@Component({
    selector: 'wiz-portal-gsdcsysadmin-board-item',
template: templateSource || '',
    styles: [`

/* file: /var/www/gsdc/project/main/build/src/app/portal.gsdcsysadmin.board.item/view.scss */
/* src/app/portal.gsdcsysadmin.board.item/view.scss: no such file or directory */`],
})
export class PortalGsdcsysadminBoardItemComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() post_id: any;
    @Input() category: any;

    @ViewChild('editor')
    public editorElement: ElementRef;
    public comments: any = [];
    public nickname: any = '';
    public comment_text: any = '';

    public item: any = {};
    public editor: any;

    public target_user: any = null;

    public userEvent: any = {
        close: async () => {
            this.target_user = null;
            await this.service.render();
        }
    }

    public async clickUser(user) {
        this.target_user = null;
        await this.service.render();
        this.target_user = user.id;
        await this.service.render();
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow.membership('admin', "/");
        this.nickname = "관리자";
        await this.load();
        await this.service.loading.hide();
    }

    public async load() {
        if (this.post_id != 'new') {
            const { code, data } = await wiz.call("get", { id: this.post_id });
            if (code != 200) return;

            this.item = data.item;
            this.comments = data.comments;
        } else {
            this.item = {
                category: this.category,
                type: 'public',
                title: '',
                content: '',
                level: '일반',
                status: 'draft',
                extra: [],
                created: moment().format("YYYY-MM-DD")
            };
        }

        await this.service.render();

        let element: any = this.editorElement.nativeElement;

        if ($(element).parent().find(".ck-content").length == 0) {
            let toolbar = 'heading | bold italic strikethrough underline | blockQuote code | bulletedList numberedList | outdent indent | uploadImage insertTable link codeBlock horizontalLine'.split(' ');

            this.editor = await ClassicEditor.create(element, {
                toolbar: {
                    items: toolbar,
                    shouldNotGroupWhenFull: true
                },
                removePlugins: ["MediaEmbedToolbar"],
                table: ClassicEditor.defaultConfig.table,
                simpleUpload: {
                    uploadUrl: '/file/upload'
                }
            });
        }

        if (!this.item.level) item.level = '일반';
        this.item.created = moment(this.item.created).format("YYYY-MM-DD");
        this.item.updated = moment(this.item.updated).format("YYYY-MM-DD");

        this.editor.data.set(this.item.content);

        await this.service.render();
    }

    public async update() {
        if (this.item.title == "") await this.service.alert.error("제목을 입력해주세요.")
        if (this.tab == 'text') this.changeTab('editor')
        this.item.content = this.editor.data.get();
        let data: any = JSON.stringify(this.item);
        await wiz.call("update", { data: data });
        await this.alert('저장되었습니다', 'Saved', 'success', '확인');

        if (!this.item.id) {
            await this.service.href(`/admin/board/${this.category}`);
        }
    }

    public tab = "editor";
    public changeTab(tab) {
        if (tab === "editor") {
            this.editor.data.set(this.item.content);
        }
        this.tab = tab;
        this.service.render();
    }

    public async delete() {
        let res: any = await this.alert("정말로 삭제하시겠습니까?", "", "error", "삭제", "취소");
        if (!res) return;
        await wiz.call("delete", { id: this.item.id });
        await this.service.href(`/admin/board/${this.category}`);
    }

    public async upload() {
        let files = await this.service.file.select();
        if (files.length == 0) return;

        await this.service.loading.show();

        if (!this.item.extra || this.item.extra == "") this.item.extra = [];
        console.log("extra : ", this.item.extra)

        for (let i = 0; i < files.length; i++) {
            let fd = new FormData();
            fd.append('upload', files[i]);
            let fdurl = '/file/upload';
            const { url } = await this.service.file.upload(fdurl, fd, async (percent: number, total: number, position: number) => { });
            this.item.extra.push({ name: files[i].name, url: url });
        }

        await this.service.loading.hide();
        this.service.render();
    }

    public async removeFile(file) {
        let res: any = await this.alert("정말로 삭제하시겠습니까?", "", "error", "삭제", "취소");
        if (!res) return;
        this.item.extra.remove(file);
        this.service.render();
    }

    public async alert(message: string, title: any = "", status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async sendComment() {
        if (!this.comment_text) return;
        await wiz.call("comment", { board_id: this.post_id, text: this.comment_text, nickname: this.nickname, category: this.category });
        this.comment_text = null;
        await this.load();
    }

    public async removeComment(comment) {
        let res = await this.service.alert.error("댓글을 삭제하시겠습니까?", "닫기", "삭제");
        if (!res) return;
        await wiz.call("remove_comment", comment);
        await this.load();
    }

}

export default PortalGsdcsysadminBoardItemComponent;