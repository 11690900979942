import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.gsdcsysuser.ticketing.item');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { ElementRef, ViewChild } from '@angular/core';
import ClassicEditor from "src/libs/portal/season/ckeditor/ckeditor";
import $ from "jquery";
import moment from "moment";

@Component({
    selector: 'wiz-portal-gsdcsysuser-ticketing-item',
template: templateSource || '',
    styles: [`

/* file: /var/www/gsdc/project/main/build/src/app/portal.gsdcsysuser.ticketing.item/view.scss */
/* src/app/portal.gsdcsysuser.ticketing.item/view.scss: no such file or directory */`],
})
export class PortalGsdcsysuserTicketingItemComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() ticket_id: any;
    // @Input() community: any;
    // @Input() community_id: any;

    @ViewChild('editor')
    public editorElement: ElementRef;
    public comments: any = [];
    public tags: any = [];
    public nickname: any = '';
    public comment_text: any = '';

    public item: any = {};
    public editor: any;

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/login");
        this.nickname = "서비스 담당자";
        await this.load();
        await this.buildEditor();
        await this.service.loading.hide();
    }

    public async load() {
        console.log("ticket : ", this.ticket_id)
        if (this.ticket_id != 'new') {
            const { code, data } = await wiz.call("get", { id: this.ticket_id });
            if (code != 200) return;
            this.item = data.item;
            this.comments = data.comments;
            this.tags = JSON.parse(data.item.tag.replace(/'/g, '"'));
        } else {
            this.item = {
                category: 'ticket',
                title: '',
                content: '',
                status: 'received',
                tags: [],
                start_date: moment().format("YYYY-MM-DD"),
                created: moment().format("YYYY-MM-DD")
            };
        }

        await this.service.render();

        this.item.due_date = moment(this.item.due_date).format("YYYY-MM-DD");

        await this.service.render();
    }

    public async buildEditor() {
        let editor = this.editor = await ClassicEditor.create(this.editorElement.nativeElement, {
            toolbar: {
                items: [
                    'undo', 'redo',
                    '|', 'heading',
                    '|', 'alignment:left', 'alignment:center', 'alignment:right', 'alignment:justify',
                    '|', 'bold', 'italic', 'strikethrough', 'underline',
                    '|', 'bulletedList', 'numberedList', 'outdent', 'indent',
                    '|', 'link', 'uploadImage', 'insertTable', 'blockQuote', 'codeBlock', 'horizontalLine'
                ],
                shouldNotGroupWhenFull: true
            },
            link: {
                addTargetToExternalLinks: true,
                defaultProtocol: 'https://',
            },
            removePlugins: ["MediaEmbedToolbar", "Markdown"],
            table: ClassicEditor.defaultConfig.table,
            simpleUpload: {
                uploadUrl: '/file/upload'
            }
        });
        if (this.ticket_id != 'new') {
            const toolbarElement = editor.ui.view.toolbar.element;
            toolbarElement.style.display = 'none';
            editor.isReadOnly = true;
        }
        this.editor.data.set(this.item.content);
    }

    public async tagAdd(target) {
        if (this.tags) this.tags.push(target);
        else this.tags = [target];
        await this.service.render();
    }

    public async tagRemove(target) {
        this.tags = this.tags.filter(tag => tag !== target);
        await this.service.render();
    }

    public async update() {
        this.item.content = this.editor.data.get();
        this.item.tag = this.tags;
        if (this.item.category == 'faq') this.item.status = "completed";
        let data: any = JSON.stringify(this.item);
        let { code } = await wiz.call("update", { data: data });
        if (code != 200) return;
        await this.alert('저장되었습니다', 'Saved', 'success', '확인');

        if (!this.item.id) {
            await this.service.href(`/ticketing`);
        }
    }

    public async delete() {
        let res: any = await this.alert("정말로 삭제하시겠습니까?", "", "error", "삭제", "취소");
        if (!res) return;
        await wiz.call("delete", { id: this.item.id });
        await this.service.href(`/ticketing`);
    }

    public async alert(message: string, title: any = "", status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async sendComment() {
        if (!this.comment_text) return;
        await wiz.call("comment", { ticket_id: this.ticket_id, text: this.comment_text, nickname: this.nickname });
        this.comment_text = null;
        await this.load();
    }

    public async removeComment(comment) {
        let res = await this.service.alert.error("댓글을 삭제하시겠습니까?", "닫기", "삭제");
        if (!res) return;
        await wiz.call("remove_comment", comment);
        await this.load();
    }

    public statusLabels: { [key: string]: string } = {
        'received': '접수완료',
        'processing': '처리중',
        'completed': '처리완료'
    };
    public getStatusLabel(status: string): string {
        return this.statusLabels[status] || status; // 변환된 값이 없으면 원래 값을 반환
    }
}

export default PortalGsdcsysuserTicketingItemComponent;