import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.gsdcsysadmin.widget.community.ticketing.list');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-gsdcsysadmin-widget-community-ticketing-list',
template: templateSource || '',
    styles: [`

/* file: /var/www/gsdc/project/main/build/src/app/portal.gsdcsysadmin.widget.community.ticketing.list/view.scss */
/* src/app/portal.gsdcsysadmin.widget.community.ticketing.list/view.scss: no such file or directory */`],
})
export class PortalGsdcsysadminWidgetCommunityTicketingListComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() community: any;
    public items: any = [];
    public loaded: boolean = false;

    public search: any = { page: 1 };

    public pagination: any = {
        end: -1,
        start: -1,
    };

    public mode: any = "";

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow.membership('admin', "/landing");
        await this.load(this.search.page);
    }

    public async load(page: number = 1) {
        this.search.page = page;
        this.search.mode = this.mode;
        this.search.community_id = this.community.id;
        this.loaded = false;
        this.items = [];
        await this.service.render();
        const { code, data } = await wiz.call("search", this.search);
        if (code != 200) return;
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.items = rows;
        this.pagination.start = startpage;
        this.pagination.end = lastpage;
        this.loaded = true;
        await this.service.render();
    }

    public async move(item) {
        await this.service.href(`/admin/community/${this.community.id}/ticketing/${item.id}`);
    }

}

export default PortalGsdcsysadminWidgetCommunityTicketingListComponent;