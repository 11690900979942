import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.lpsysadmin.fund.item');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-lpsysadmin-fund-item',
template: templateSource || '',
    styles: [`

/* file: /var/www/gsdc/project/main/build/src/app/portal.lpsysadmin.fund.item/view.scss */
/* src/app/portal.lpsysadmin.fund.item/view.scss: no such file or directory */`],
})
export class PortalLpsysadminFundItemComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() fund_id: any;
    @Input() menu: any;
    @Input() sub: any;

    public fund: any = null;
    public investments: any = [];

    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    public async load() {
        if (this.fund_id == "new") {
            this.fund = { investment_status: '결성', investment_stage: '참여신청', mgmt_rate: [null, null, null, null, null, null, null, null], extra: {}, visibility: {}, isnew: true };
            this.investments = [];
            await this.service.render();
            return;
        }
        const { code, data } = await wiz.call("info", { id: this.fund_id });
        this.fund = data.info;
        this.investments = data.investments;
        await this.service.render();
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public linkFundMenu(menu: string) {
        return `/admin/fund/${this.sub}/${this.fund_id}/${menu}`;
    }

    public isActive(link: string) {
        return location.pathname.indexOf(link) === 0
    }

    public activeClass(link: string) {
        if (this.isActive(link)) {
            return "group flex gap-x-3 rounded-md bg-gray-50 py-2 pl-2 pr-3 text-sm font-semibold leading-6 text-indigo-600";
        }
        return "group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600";
    }
}

export default PortalLpsysadminFundItemComponent;