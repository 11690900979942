import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.lpsysadmin.widget.company.board');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { ElementRef, ViewChild } from '@angular/core';
import ClassicEditor from "src/libs/portal/season/ckeditor/ckeditor";
import $ from "jquery";
import moment from "moment";

@Component({
    selector: 'wiz-portal-lpsysadmin-widget-company-board',
template: templateSource || '',
    styles: [`

/* file: /var/www/gsdc/project/main/build/src/app/portal.lpsysadmin.widget.company.board/view.scss */
/* src/app/portal.lpsysadmin.widget.company.board/view.scss: no such file or directory */`],
})
export class PortalLpsysadminWidgetCompanyBoardComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }
    @ViewChild('editor')
    public editorElement: ElementRef;
    public post_id: any;
    public comments: any;
    public vote: any;
    public nickname: any;
    public comment_text: any;

    @Input() company: any;
    @Input() category: any;

    public items: any = [];
    public loaded: boolean = false;

    public item: any = {};
    public editor: any;

    public search: any = {
        page: 1,
        text: "",
    };

    public pagination: any = {
        end: -1,
        start: -1,
    };

    public target_user: any = null;

    public userEvent: any = {
        close: async () => {
            this.target_user = null;
            await this.service.render();
        }
    }

    public async clickUser(user) {
        this.target_user = null;
        await this.service.render();
        this.target_user = user.id;
        await this.service.render();
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow.membership('admin', "/");
        this.search.company_id = this.company.id;
        this.search.category = this.category;
        this.nickname = "관리자";
        await this.load();
    }

    public async load(page: number = 1) {
        this.search.page = page;
        this.loaded = false;
        this.items = [];
        await this.service.render();
        let body = JSON.parse(JSON.stringify(this.search));
        body.category = this.category;
        const { code, data } = await wiz.call("search", body);
        if (code != 200) return;
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.items = rows;
        this.pagination.start = startpage;
        this.pagination.end = lastpage;
        this.loaded = true;
        await this.service.render();
    }

    public async update() {
        this.item.content = this.editor.data.get();
        this.item.company_id = this.company.id;
        let data: any = JSON.stringify(this.item);
        await wiz.call("update", { data: data });
        await this.alert('저장되었습니다', 'Saved', 'success', '확인');

        if (!this.item.id) {
            await this.previous();
            await this.load(this.search.page);
        }
    }

    public async delete() {
        let res: any = await this.alert("정말로 삭제하시겠습니까?", "", "error", "삭제", "취소");
        if (!res) return;
        await wiz.call("delete", { id: this.item.id });
        await this.previous();
        await this.load(this.search.page);
    }

    public async upload() {
        let files = await this.service.file.select();
        if (files.length == 0) return;

        await this.service.loading.show();

        if (!this.item.extra.attachments) this.item.extra.attachments = [];

        for (let i = 0; i < files.length; i++) {
            let fd = new FormData();
            fd.append('upload', files[i]);
            let fdurl = '/file/upload';
            const { url } = await this.service.file.upload(fdurl, fd, async (percent: number, total: number, position: number) => { });
            this.item.extra.attachments.push({ name: files[i].name, url: url });
        }

        await this.service.loading.hide();
        this.service.render();
    }

    public async removeFile(file) {
        let res: any = await this.alert("정말로 삭제하시겠습니까?", "", "error", "삭제", "취소");
        if (!res) return;
        this.item.extra.attachments.remove(file);
        this.service.render();
    }

    public async alert(message: string, title: any = "", status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async select(item) {
        if (item.id != 'new') {
            const { code, data } = await wiz.call("get", { id: item.id });
            if (code != 200) return await this.previous();
            item = data.item;
            this.vote = data.vote;
            this.comments = data.comments;
        }

        this.post_id = item.id;
        await this.service.render();

        let element: any = this.editorElement.nativeElement;

        if ($(element).parent().find(".ck-content").length == 0) {
            let toolbar = 'heading | bold italic strikethrough underline | blockQuote code | bulletedList numberedList | outdent indent | uploadImage insertTable link codeBlock horizontalLine'.split(' ');

            this.editor = await ClassicEditor.create(element, {
                toolbar: {
                    items: toolbar,
                    shouldNotGroupWhenFull: true
                },
                removePlugins: ["MediaEmbedToolbar"],
                table: ClassicEditor.defaultConfig.table,
                simpleUpload: {
                    uploadUrl: '/file/upload'
                }
            });
        }

        if (item.id == 'new') {
            this.item = {
                category: this.category,
                fund_id: this.company.id,
                title: '',
                content: '',
                level: '일반',
                status: 'publish',
                mode: '일반',
                extra: {},
                created: moment().format("YYYY-MM-DD")
            };
        } else {
            this.item = item;
            if (!this.item.level) item.level = '일반';
            this.item.created = moment(item.created).format("YYYY-MM-DD");
        }

        this.editor.data.set(this.item.content);

        if (!this.item.extra.vote) this.item.extra.vote = [];

        await this.service.render();
    }

    public async previous() {
        this.post_id = null;
        this.vote = null;
        this.comments = null;
        this.item = {};
        await this.service.render();
    }

    public async actionVote() {
        if (!this.post_id) return;
        await wiz.call("vote", { board_id: this.post_id, vote: this.vote, nickname: this.nickname });
        await this.select(this.item);
    }

    public async sendComment() {
        if (!this.comment_text) return;
        await wiz.call("comment", { board_id: this.post_id, text: this.comment_text, nickname: this.nickname });
        this.comment_text = null;
        await this.select(this.item);
    }

    public async removeComment(comment) {
        let res = await this.service.alert.error("댓글을 삭제하시겠습니까?", "닫기", "삭제");
        if (!res) return;
        await wiz.call("remove_comment", comment);
        await this.select(this.item);
    }

    public async toggleVote() {
        let res = true;
        if (this.item.extra.vote_end) res = await this.service.alert.error("투표를 재개하시겠습니까?", "닫기", "재개");
        else res = await this.service.alert.error("투표를 종료하시겠습니까?", "닫기", "종료");
        if (!res) return;
        this.item.extra.vote_end = !this.item.extra.vote_end;
        await this.service.render();
        await this.update();
    }

}

export default PortalLpsysadminWidgetCompanyBoardComponent;