import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.lpsys.fund.list.operating');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";
import moment from 'moment';

@Component({
    selector: 'wiz-portal-lpsys-fund-list-operating',
template: templateSource || '',
    styles: [`

/* file: /var/www/gsdc/project/main/build/src/app/portal.lpsys.fund.list.operating/view.scss */
/* src/app/portal.lpsys.fund.list.operating/view.scss: no such file or directory */`],
})
export class PortalLpsysFundListOperatingComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public data: any = [];

    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    public async load() {
        const { data } = await wiz.call("load");
        this.data = data;
        await this.service.render();
    }

    public iterItems(item: any) {
        if (item.investment.length == 0) {
            let row: any = JSON.parse(JSON.stringify(item));
            row.investment = {};
            row._rowspan = 1;
            return [row];
        }

        let rows: any = [];
        for (let i = 0; i < item.investment.length; i++) {
            let row: any = JSON.parse(JSON.stringify(item));
            if (i == 0) row._rowspan = item.investment.length;
            row.investment = item.investment[i];
            rows.push(row);
        }

        return rows;
    }

    public formatter: any = {
        date: (date) => {
            if (!date) return "-";
            let res: any = moment(date).format("YYYY-MM-DD");
            if (res == 'Invalid date') return "-";
            return res;
        },
        manager: (name) => {
            if (!name) return "-";
            return name;
        }
    }
}

export default PortalLpsysFundListOperatingComponent;