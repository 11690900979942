import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.request');
import { OnInit, Input, DoCheck } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-request',
template: templateSource || '',
    styles: [`

/* file: /var/www/gsdc/project/main/build/src/app/page.request/view.scss */
/* src/app/page.request/view.scss: no such file or directory */`],
})
export class PageRequestComponent implements OnInit, DoCheck {

    public id: any = '';

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/login");
        this.id = WizRoute.segment.id;
        await this.service.render();
    }

    public async ngDoCheck() {
        this.id = WizRoute.segment.id;
    }
}

export default PageRequestComponent;