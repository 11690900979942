import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.dashboard');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { ElementRef, ViewChild } from '@angular/core';
import ClassicEditor from "src/libs/portal/season/ckeditor/ckeditor";
import showdown from 'showdown';

@Component({
    selector: 'wiz-page-dashboard',
template: templateSource || '',
    styles: [`

/* file: /var/www/gsdc/project/main/build/src/app/page.dashboard/view.scss */
/* src/app/page.dashboard/view.scss: no such file or directory */`],
})
export class PageDashboardComponent implements OnInit {

    public isLoading: boolean = true;
    public community_id: any;
    public community: any = {};

    @ViewChild('editor')
    public editorElement: ElementRef;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/login");
        this.isLoading = false;
        await this.load();
        await this.buildEditor();
    }

    public async load() {
        const { code, data } = await wiz.call("load");
        if (code != 200) return;
        this.community = data;
        await this.service.render();
    }

    public async buildEditor() {
        let editor = this.editor = await ClassicEditor.create(this.editorElement.nativeElement, {
            toolbar: {
                items: [
                    'undo', 'redo',
                    '|', 'heading',
                    '|', 'alignment:left', 'alignment:center', 'alignment:right', 'alignment:justify',
                    '|', 'bold', 'italic', 'strikethrough', 'underline',
                    '|', 'bulletedList', 'numberedList', 'outdent', 'indent',
                    '|', 'link', 'uploadImage', 'insertTable', 'blockQuote', 'codeBlock', 'horizontalLine'
                ],
                shouldNotGroupWhenFull: true
            },
            codeBlock: {
                languages: [
                    { language: 'plaintext', label: '평문' },
                    { language: 'c', label: 'C' },
                    { language: 'cpp', label: 'C++' },
                    { language: 'cs', label: 'C#' },
                    { language: 'css', label: 'CSS' },
                    { language: 'diff', label: 'Diff' },
                    { language: 'html', label: 'HTML' },
                    { language: 'java', label: 'Java' },
                    { language: 'javascript', label: 'JavaScript' },
                    { language: 'markdown', label: 'Markdown' }, // 여기서 Markdown 추가
                    { language: 'php', label: 'PHP' },
                    { language: 'python', label: 'Python' },
                    { language: 'ruby', label: 'Ruby' },
                    { language: 'typescript', label: 'TypeScript' },
                    { language: 'xml', label: 'XML' }
                ]
            },
            link: {
                addTargetToExternalLinks: true,
                defaultProtocol: 'https://',
            },
            removePlugins: ["MediaEmbedToolbar"],
            table: ClassicEditor.defaultConfig.table,
            simpleUpload: {
                uploadUrl: '/file/upload'
            }
        });

        const toolbarElement = editor.ui.view.toolbar.element;
        toolbarElement.style.display = 'none';
        editor.isReadOnly = true;
        editor.ui.view.editable.element.classList.add('border-0');
        this.editor.data.set(this.community.description);

    }

}

export default PageDashboardComponent;