import '@angular/compiler';
import { PortalLpsysadminWidgetFundBoardComponent } from './portal.lpsysadmin.widget.fund.board/portal.lpsysadmin.widget.fund.board.component';
import { PortalLpsysadminWidgetCompanyEditorComponent } from './portal.lpsysadmin.widget.company.editor/portal.lpsysadmin.widget.company.editor.component';
import { PortalGsdcsysuserRequestListComponent } from './portal.gsdcsysuser.request.list/portal.gsdcsysuser.request.list.component';
import { PortalGsdcsysadminRequestListComponent } from './portal.gsdcsysadmin.request.list/portal.gsdcsysadmin.request.list.component';
import { PageTicketingIdComponent } from './page.ticketing.id/page.ticketing.id.component';
import { PortalGsdcsysadminCommunityListComponent } from './portal.gsdcsysadmin.community.list/portal.gsdcsysadmin.community.list.component';
import { PortalLpsysWidgetFundItemInvestmentComponent } from './portal.lpsys.widget.fund.item.investment/portal.lpsys.widget.fund.item.investment.component';
import { PortalGsdcsysadminWidgetCommunityInfoEditComponent } from './portal.gsdcsysadmin.widget.community.info.edit/portal.gsdcsysadmin.widget.community.info.edit.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PortalGsdcsysadminWidgetCommunityGroupSettingComponent } from './portal.gsdcsysadmin.widget.community.group.setting/portal.gsdcsysadmin.widget.community.group.setting.component';
import { PageUserComponent } from './page.user/page.user.component';
import { PortalSeasonLoadingComponent } from './portal.season.loading/portal.season.loading.component';
import { PortalGsdcsysadminUserListComponent } from './portal.gsdcsysadmin.user.list/portal.gsdcsysadmin.user.list.component';
import { PortalLpsysWidgetCompanyViewerComponent } from './portal.lpsys.widget.company.viewer/portal.lpsys.widget.company.viewer.component';
import { PortalLpsysadminWidgetUserProfileComponent } from './portal.lpsysadmin.widget.user.profile/portal.lpsysadmin.widget.user.profile.component';
import { PortalGsdcsysuserBoardItemComponent } from './portal.gsdcsysuser.board.item/portal.gsdcsysuser.board.item.component';
import { PortalLpsysadminWidgetFundInfoComponent } from './portal.lpsysadmin.widget.fund.info/portal.lpsysadmin.widget.fund.info.component';
import { PortalGsdcsysadminWidgetCommunityUserComponent } from './portal.gsdcsysadmin.widget.community.user/portal.gsdcsysadmin.widget.community.user.component';
import { PageResetPasswordComponent } from './page.reset.password/page.reset.password.component';
import { PortalLpsysCommunityBoardComponent } from './portal.lpsys.community.board/portal.lpsys.community.board.component';
import { PortalLpsysadminFundListOperationComponent } from './portal.lpsysadmin.fund.list.operation/portal.lpsysadmin.fund.list.operation.component';
import { PortalGsdcsysuserTicketingItemComponent } from './portal.gsdcsysuser.ticketing.item/portal.gsdcsysuser.ticketing.item.component';
import { PortalLpsysMyprofileInfoComponent } from './portal.lpsys.myprofile.info/portal.lpsys.myprofile.info.component';
import { PortalGsdcsysadminTicketingListComponent } from './portal.gsdcsysadmin.ticketing.list/portal.gsdcsysadmin.ticketing.list.component';
import { PortalGsdcsysadminUserAddComponent } from './portal.gsdcsysadmin.user.add/portal.gsdcsysadmin.user.add.component';
import { PortalLpsysadminWidgetCompanyProductComponent } from './portal.lpsysadmin.widget.company.product/portal.lpsysadmin.widget.company.product.component';
import { PortalLpsysadminFundListFormedComponent } from './portal.lpsysadmin.fund.list.formed/portal.lpsysadmin.fund.list.formed.component';
import { PageOtpIssuanceComponent } from './page.otp.issuance/page.otp.issuance.component';
import { PortalLpsysadminWidgetCompanyBoardComponent } from './portal.lpsysadmin.widget.company.board/portal.lpsysadmin.widget.company.board.component';
import { PortalLpsysWidgetBoardComponent } from './portal.lpsys.widget.board/portal.lpsys.widget.board.component';
import { PortalLpsysCommunityViewComponent } from './portal.lpsys.community.view/portal.lpsys.community.view.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { PortalLpsysFundListOperatingComponent } from './portal.lpsys.fund.list.operating/portal.lpsys.fund.list.operating.component';
import { PageMyprofileComponent } from './page.myprofile/page.myprofile.component';
import { PageBoardIdComponent } from './page.board.id/page.board.id.component';
import { PageResearchIdComponent } from './page.research.id/page.research.id.component';
import { PortalGsdcsysadminWidgetCommunitySettingComponent } from './portal.gsdcsysadmin.widget.community.setting/portal.gsdcsysadmin.widget.community.setting.component';
import { PageGroupIdSubComponent } from './page.group.id.sub/page.group.id.sub.component';
import { PortalGsdcsysuserGroupListComponent } from './portal.gsdcsysuser.group.list/portal.gsdcsysuser.group.list.component';
import { PortalSeasonPaginationComponent } from './portal.season.pagination/portal.season.pagination.component';
import { PageJoinComponent } from './page.join/page.join.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PortalGsdcsysuserTicketingListComponent } from './portal.gsdcsysuser.ticketing.list/portal.gsdcsysuser.ticketing.list.component';
import { PortalGsdcsysadminTicketingItemComponent } from './portal.gsdcsysadmin.ticketing.item/portal.gsdcsysadmin.ticketing.item.component';
import { PortalGsdcsysuserGroupItemComponent } from './portal.gsdcsysuser.group.item/portal.gsdcsysuser.group.item.component';
import { PortalGsdcsysadminWidgetCommunityBoardItemComponent } from './portal.gsdcsysadmin.widget.community.board.item/portal.gsdcsysadmin.widget.community.board.item.component';
import { PageCommunityComponent } from './page.community/page.community.component';
import { PortalLpsysadminCompanyListComponent } from './portal.lpsysadmin.company.list/portal.lpsysadmin.company.list.component';
import { PortalGsdcsysuserResearchListComponent } from './portal.gsdcsysuser.research.list/portal.gsdcsysuser.research.list.component';
import { PortalGsdcsysuserResearchItemComponent } from './portal.gsdcsysuser.research.item/portal.gsdcsysuser.research.item.component';
import { PortalLpsysadminWidgetCompanyInfoComponent } from './portal.lpsysadmin.widget.company.info/portal.lpsysadmin.widget.company.info.component';
import { PortalLpsysadminBoardItemComponent } from './portal.lpsysadmin.board.item/portal.lpsysadmin.board.item.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { PortalLpsysWidgetPdfviewerComponent } from './portal.lpsys.widget.pdfviewer/portal.lpsys.widget.pdfviewer.component';
import { PortalGsdcsysadminWidgetUserProfileComponent } from './portal.gsdcsysadmin.widget.user.profile/portal.gsdcsysadmin.widget.user.profile.component';
import { PortalGsdcsysadminWidgetCommunityGroupListComponent } from './portal.gsdcsysadmin.widget.community.group.list/portal.gsdcsysadmin.widget.community.group.list.component';
import { LayoutNavbarComponent } from './layout.navbar/layout.navbar.component';
import { PortalLpsysadminCompanyItemComponent } from './portal.lpsysadmin.company.item/portal.lpsysadmin.company.item.component';
import { PortalLpsysFundListExitComponent } from './portal.lpsys.fund.list.exit/portal.lpsys.fund.list.exit.component';
import { PortalGsdcsysadminWidgetCommunityTicketingListComponent } from './portal.gsdcsysadmin.widget.community.ticketing.list/portal.gsdcsysadmin.widget.community.ticketing.list.component';
import { PortalGsdcsysuserWidgetGroupInfoComponent } from './portal.gsdcsysuser.widget.group.info/portal.gsdcsysuser.widget.group.info.component';
import { PortalGsdcsysuserBoardListComponent } from './portal.gsdcsysuser.board.list/portal.gsdcsysuser.board.list.component';
import { PortalLpsysadminWidgetFundMemberComponent } from './portal.lpsysadmin.widget.fund.member/portal.lpsysadmin.widget.fund.member.component';
import { LayoutSidebarComponent } from './layout.sidebar/layout.sidebar.component';
import { PortalLpsysadminWidgetFundMemberSellComponent } from './portal.lpsysadmin.widget.fund.member.sell/portal.lpsysadmin.widget.fund.member.sell.component';
import { PortalLpsysFundListFormedComponent } from './portal.lpsys.fund.list.formed/portal.lpsys.fund.list.formed.component';
import { PortalLpsysFundItemComponent } from './portal.lpsys.fund.item/portal.lpsys.fund.item.component';
import { PortalLpsysadminWidgetFundInvestmentComponent } from './portal.lpsysadmin.widget.fund.investment/portal.lpsysadmin.widget.fund.investment.component';
import { PortalGsdcsysadminBoardListComponent } from './portal.gsdcsysadmin.board.list/portal.gsdcsysadmin.board.list.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { PortalGsdcsysadminWidgetCommunityGroupUserComponent } from './portal.gsdcsysadmin.widget.community.group.user/portal.gsdcsysadmin.widget.community.group.user.component';
import { PortalGsdcsysadminWidgetCommunityGroupInfoComponent } from './portal.gsdcsysadmin.widget.community.group.info/portal.gsdcsysadmin.widget.community.group.info.component';
import { PortalGsdcsysadminWidgetCommunityBoardListComponent } from './portal.gsdcsysadmin.widget.community.board.list/portal.gsdcsysadmin.widget.community.board.list.component';
import { PortalGsdcsysadminWidgetCommunityTicketingItemComponent } from './portal.gsdcsysadmin.widget.community.ticketing.item/portal.gsdcsysadmin.widget.community.ticketing.item.component';
import { PortalLpsysWidgetFundItemDocsComponent } from './portal.lpsys.widget.fund.item.docs/portal.lpsys.widget.fund.item.docs.component';
import { PortalLpsysadminWidgetFundInviteComponent } from './portal.lpsysadmin.widget.fund.invite/portal.lpsysadmin.widget.fund.invite.component';
import { PortalLpsysCompanyItemComponent } from './portal.lpsys.company.item/portal.lpsys.company.item.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PageMypageComponent } from './page.mypage/page.mypage.component';
import { PageCompanyItemComponent } from './page.company.item/page.company.item.component';
import { PortalLpsysMyprofilePasswordComponent } from './portal.lpsys.myprofile.password/portal.lpsys.myprofile.password.component';
import { ComponentNavSidebarComponent } from './component.nav.sidebar/component.nav.sidebar.component';
import { PortalLpsysadminFundListExitComponent } from './portal.lpsysadmin.fund.list.exit/portal.lpsysadmin.fund.list.exit.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { PortalLpsysadminWidgetFundDocumentComponent } from './portal.lpsysadmin.widget.fund.document/portal.lpsysadmin.widget.fund.document.component';
import { PageRequestComponent } from './page.request/page.request.component';
import { PortalLpsysWidgetCompanyProductComponent } from './portal.lpsys.widget.company.product/portal.lpsys.widget.company.product.component';
import { PortalGsdcsysadminCommunityItemComponent } from './portal.gsdcsysadmin.community.item/portal.gsdcsysadmin.community.item.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { PageDashboardComponent } from './page.dashboard/page.dashboard.component';
import { PortalGsdcsysuserWidgetGroupUserComponent } from './portal.gsdcsysuser.widget.group.user/portal.gsdcsysuser.widget.group.user.component';
import { PageFundItemComponent } from './page.fund.item/page.fund.item.component';
import { PortalLpsysWidgetFundItemInfoComponent } from './portal.lpsys.widget.fund.item.info/portal.lpsys.widget.fund.item.info.component';
import { PortalGsdcsysadminBoardItemComponent } from './portal.gsdcsysadmin.board.item/portal.gsdcsysadmin.board.item.component';
import { PortalGsdcsysuserGroupUserComponent } from './portal.gsdcsysuser.group.user/portal.gsdcsysuser.group.user.component';
import { PortalLpsysadminFundItemComponent } from './portal.lpsysadmin.fund.item/portal.lpsysadmin.fund.item.component';
import { PortalLpsysMyprofileEditComponent } from './portal.lpsys.myprofile.edit/portal.lpsys.myprofile.edit.component';
import { PageLoginComponent } from './page.login/page.login.component';
import { PortalGsdcsysadminWidgetCommunityGroupItemComponent } from './portal.gsdcsysadmin.widget.community.group.item/portal.gsdcsysadmin.widget.community.group.item.component';
import { PageFindpwComponent } from './page.findpw/page.findpw.component';
import { PortalLpsysadminBoardListComponent } from './portal.lpsysadmin.board.list/portal.lpsysadmin.board.list.component';
import { PortalGsdcsysadminResearchListComponent } from './portal.gsdcsysadmin.research.list/portal.gsdcsysadmin.research.list.component';
import { PortalGsdcsysadminResearchItemComponent } from './portal.gsdcsysadmin.research.item/portal.gsdcsysadmin.research.item.component';
import { PortalLpsysadminUserListComponent } from './portal.lpsysadmin.user.list/portal.lpsysadmin.user.list.component';
import { PageAdminComponent } from './page.admin/page.admin.component';

const INDEX_PAGE = "dashboard";

import { URLPattern } from "urlpattern-polyfill";
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

let app_routes: Routes = [
    {
        component: LayoutNavbarComponent,
        "children": [
            {
                "path": "ticketing/:id?",
                component: PageTicketingIdComponent,
                "app_id": "page.ticketing.id"
            },
            {
                "path": "users",
                component: PageUserComponent,
                "app_id": "page.user"
            },
            {
                "path": "myprofile",
                component: PageMyprofileComponent,
                "app_id": "page.myprofile"
            },
            {
                "path": "board/:category/:id?",
                component: PageBoardIdComponent,
                "app_id": "page.board.id"
            },
            {
                "path": "research/:id?",
                component: PageResearchIdComponent,
                "app_id": "page.research.id"
            },
            {
                "path": "group/:id?/:sub?",
                component: PageGroupIdSubComponent,
                "app_id": "page.group.id.sub"
            },
            {
                "path": "community/:category?/:menu?/:id?",
                component: PageCommunityComponent,
                "app_id": "page.community"
            },
            {
                "path": "mypage",
                component: PageMypageComponent,
                "app_id": "page.mypage"
            },
            {
                "path": "company/:main/:id/:menu",
                component: PageCompanyItemComponent,
                "app_id": "page.company.item"
            },
            {
                "path": "request",
                component: PageRequestComponent,
                "app_id": "page.request"
            },
            {
                "path": "dashboard",
                component: PageDashboardComponent,
                "app_id": "page.dashboard"
            },
            {
                "path": "fund/:status?/:id?/:menu?/:sub?",
                component: PageFundItemComponent,
                "app_id": "page.fund.item"
            }
        ]
    },
    {
        component: LayoutEmptyComponent,
        "children": [
            {
                "path": "reset/password",
                component: PageResetPasswordComponent,
                "app_id": "page.reset.password"
            },
            {
                "path": "otp/issuance",
                component: PageOtpIssuanceComponent,
                "app_id": "page.otp.issuance"
            },
            {
                "path": "join",
                component: PageJoinComponent,
                "app_id": "page.join"
            },
            {
                "path": "login",
                component: PageLoginComponent,
                "app_id": "page.login"
            },
            {
                "path": "findpw",
                component: PageFindpwComponent,
                "app_id": "page.findpw"
            }
        ]
    },
    {
        component: LayoutSidebarComponent,
        "children": [
            {
                "path": "admin/:main?/:sub?/:id?/:menu?",
                component: PageAdminComponent,
                "app_id": "page.admin"
            }
        ]
    }
];

export class RouteInfo {
    public path: any = "";
    public segment: any = {};

    constructor() { }
}

window.WizRoute = new RouteInfo();

let patternMatcher = (pattern: any, url: any) => {
    let urlpath = url.map((x: any) => x.path).join("/");
    let testurl = 'http://test/';
    pattern = '/' + pattern;
    urlpath = testurl + urlpath;
    pattern = new URLPattern({ pathname: pattern });
    pattern = pattern.exec(urlpath)
    if (pattern && pattern.pathname) {
        let posParams = {};
        for (let key in pattern.pathname.groups) {
            if (pattern.pathname.groups[key]) {
                posParams[key] = pattern.pathname.groups[key];
            }
        }
        window.WizRoute.path = url.map((x: any) => x.path).join("/");
        window.WizRoute.segment = posParams;

        return { consumed: url, posParams: posParams };
    }
    return null
}

let routes: Routes = [{
    matcher: (url: any) => {
        for (let i = 0; i < app_routes.length; i++) {
            let layout = app_routes[i];
            let layout_childs = layout.children;
            for (let j = 0; j < layout_childs.length; j++) {
                let child = layout_childs[j];
                let matcher = patternMatcher(child.path, url);
                if (matcher)
                    return null;
            }
        }
        return { consumed: url, posParams: {} };
    },
    redirectTo: INDEX_PAGE
}];

for (let i = 0; i < app_routes.length; i++) {
    let layout = app_routes[i];
    let layout_component = layout.component;
    let layout_childs = layout.children;

    let router: any = {
        path: '',
        component: layout_component,
        children: []
    };

    for (let j = 0; j < layout_childs.length; j++) {
        let child = layout_childs[j];
        router.children.push({
            matcher: (url: any) => {
                let matcher = patternMatcher(child.path, url);
                if (matcher) return matcher;
                return null;
            },
            component: child.component
        });
    }
    routes.push(router);
}

@NgModule({ imports: [RouterModule.forRoot(routes)], exports: [RouterModule] })
export class AppRoutingModule { }