import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.gsdcsysuser.board.item');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { ElementRef, ViewChild } from '@angular/core';
import ClassicEditor from "src/libs/portal/season/ckeditor/ckeditor";
import $ from "jquery";
import moment from "moment";

@Component({
    selector: 'wiz-portal-gsdcsysuser-board-item',
template: templateSource || '',
    styles: [`

/* file: /var/www/gsdc/project/main/build/src/app/portal.gsdcsysuser.board.item/view.scss */
/* src/app/portal.gsdcsysuser.board.item/view.scss: no such file or directory */`],
})
export class PortalGsdcsysuserBoardItemComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() board_id: any;
    @Input() category: any;

    @ViewChild('editor')
    public editorElement: ElementRef;
    public comments: any = [];
    public nickname: any = '';
    public comment_text: any = '';

    public item: any = {};
    public editor: any;

    public target_user: any = null;

    public userEvent: any = {
        close: async () => {
            this.target_user = null;
            await this.service.render();
        }
    }

    public async clickUser(user) {
        this.target_user = null;
        await this.service.render();
        this.target_user = user.id;
        await this.service.render();
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/login");
        this.nickname = "사용자";
        await this.load();
        await this.buildEditor();
        await this.service.loading.hide();
    }

    public async load() {
        const { code, data } = await wiz.call("get", { id: this.board_id });
        if (code != 200) return;
        console.log("data : ", data)

        this.item = data.item;
        this.comments = data.comments;

        await this.service.render();

        if (!this.item.level) item.level = '일반';
        this.item.created = moment(this.item.created).format("YYYY-MM-DD");
        this.item.updated = moment(this.item.updated).format("YYYY-MM-DD");

        await this.service.render();

        // let element: any = this.editorElement.nativeElement;

        // if ($(element).parent().find(".ck-content").length == 0) {
        //     let toolbar = 'heading | bold italic strikethrough underline | blockQuote code | bulletedList numberedList | outdent indent | uploadImage insertTable link codeBlock horizontalLine'.split(' ');

        //     this.editor = await ClassicEditor.create(element, {
        //         toolbar: {
        //             items: toolbar,
        //             shouldNotGroupWhenFull: true
        //         },
        //         removePlugins: ["MediaEmbedToolbar"],
        //         table: ClassicEditor.defaultConfig.table,
        //         simpleUpload: {
        //             uploadUrl: '/file/upload'
        //         }
        //     });
        // }

        // this.editor.data.set(this.item.content);

        await this.service.render();
    }

    public async buildEditor() {
        let editor = this.editor = await ClassicEditor.create(this.editorElement.nativeElement, {
            toolbar: {
                items: [
                    'undo', 'redo',
                    '|', 'heading',
                    '|', 'alignment:left', 'alignment:center', 'alignment:right', 'alignment:justify',
                    '|', 'bold', 'italic', 'strikethrough', 'underline',
                    '|', 'bulletedList', 'numberedList', 'outdent', 'indent',
                    '|', 'link', 'uploadImage', 'insertTable', 'blockQuote', 'codeBlock', 'horizontalLine'
                ],
                shouldNotGroupWhenFull: true
            },
            link: {
                addTargetToExternalLinks: true,
                defaultProtocol: 'https://',
            },
            removePlugins: ["MediaEmbedToolbar"],
            table: ClassicEditor.defaultConfig.table,
            simpleUpload: {
                uploadUrl: '/file/upload'
            }
        });

        const toolbarElement = editor.ui.view.toolbar.element;
        toolbarElement.style.display = 'none';
        editor.isReadOnly = true;
        this.editor.data.set(this.item.content);
    }

    public async sendComment() {
        if (!this.comment_text) return;
        await wiz.call("comment", { board_id: this.board_id, text: this.comment_text, nickname: this.nickname, category: this.category });
        this.comment_text = null;
        await this.load();
    }

    public async removeComment(comment) {
        let res = await this.service.alert.error("댓글을 삭제하시겠습니까?", "닫기", "삭제");
        if (!res) return;
        await wiz.call("remove_comment", comment);
        await this.load();
    }

}

export default PortalGsdcsysuserBoardItemComponent;