import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.xyz');
import { OnInit } from "@angular/core";
import toastr from "toastr";


@Component({
    selector: 'wiz-page-xyz',
template: templateSource || '',
    styles: [``],
})
export class PageXyzComponent implements OnInit {

    public text: string = 'Hello, World!';

    constructor() {
        this.text = `${this.text} this page is '${WizRoute.segment.page}'`;
    }

    public async ngOnInit() {
        // request to app's API
        let { code, data } = await wiz.call("status");
        console.log(code, data);

        // connect to this app's socket
        let socket = wiz.socket();
        socket.on("connect", async () => {
            toastr.success("socket connected");
        });
    }
}

export default PageXyzComponent;